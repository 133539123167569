import { Controller } from "stimulus";

export default class extends Controller {
	static targets = ["formWrapper"];

	show(e) {
		e.preventDefault();
		this.formWrapperTarget.classList.remove("is-hidden");
	}

	hide(e) {
		e.preventDefault();
		this.formWrapperTarget.classList.add("is-hidden");
	}
}
