import { Controller } from "stimulus";
import { htmlToElement } from "../utils";

export default class extends Controller {
	static targets = ["email", "row", "title"];

	edit(e) {
		e.preventDefault();
		const title = this.data.get("title");
		const email = this.data.get("email");
		const id = this.data.get("id");

		this.rowTarget.innerHTML = "";
		this.rowTarget.append(
			...htmlToElement(`
			<td>
				<input id="title" name="title" value="${title}" data-target="party-row.title">
			</td>
			<td>
				<input id="email" name="email" value="${email}" data-target="party-row.email">
			</td>
			<td>
				<input type="hidden" name="party_id" value="${id}">
				<input type="submit" class="button is-success" value="Save">
				<button class="button" value="Cancel" data-action="party-row#cancel">Cancel</button>
			</td>
			`)
		);
	}

	cancel(e) {
		e.preventDefault();
		const title = this.data.get("title");
		const email = this.data.get("email");

		this.rowTarget.innerHTML = "";
		this.rowTarget.append(
			...htmlToElement(`
			<td>${title}</td>
            <td>${email}</td>
            <td><a href="#" data-action="party-row#remove">Remove</a> <a href="#" data-action="party-row#edit">Edit</a></td>
          `)
		);
	}
}
