import { Controller } from "stimulus";

export default class extends Controller {
	new(e) {
		e.preventDefault();
		const modal = document.getElementById("new-gift-modal");
		console.log(modal);
		modal.classList.add("is-active");

		const html = document.documentElement;
		html.classList.add("is-clipped");
	}
}
