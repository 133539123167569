import { Controller } from "stimulus";

export default class extends Controller {
	static targets = ["guest"];

	toggleGuest(e) {
		const checked = e.target.checked;

		if (checked) {
			this.guestTargets.forEach(t => t.classList.add("is-hidden"));
		} else {
			this.guestTargets.forEach(t => t.classList.remove("is-hidden"));
		}
	}
}
